<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <span>群名称:</span>
            <el-select v-model="searchOption.groupObj" value-key="GroupID" placeholder="全部" @change="changeSelectAction">
              <el-option v-for="item in groups" :key="item.GroupID" :label="item.GroupName" :value="item">
              </el-option>
            </el-select>
          </div>
        <div>
            <span>查询：</span>
            <el-input v-model="searchOption.key" placeholder="请输入成员昵称或成员群昵称"></el-input>
          </div>
        </el-space>
        <div>
            <el-button type="primary" @click="searchAction">搜索</el-button>
            <el-button @click="resetAction">重置</el-button>
          </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
		<div class="list-title">
			<div>成员列表(共{{pageObj.TotalRow||0}}个)</div>
            <el-button type="primary" @click="exportAction">导出</el-button>
		</div>
      <el-table :data="tableData" style="width: 100%" border @sort-change="changeSort">
        <el-table-column label="成员头像" width="70px">
            <template #default="scope">
                <div class="action-wrap">
                    <img src="@/assets/imgs/default_user.png" alt="" v-if="!scope.row.MemberHeadImgUrl">
                    <img :src="scope.row.MemberHeadImgUrl" alt="" v-else>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="MemberNickName" label="成员昵称"></el-table-column>
        <el-table-column prop="MemberGroupNickName" label="成员群昵称"></el-table-column>
        <el-table-column label="群名称">
            <template #default="scope">
                <div class="action-wrap">
                    <div>{{scope.row.GroupName}}</div>
                    <!-- <i class="el-icon-platform-eleme"></i> -->
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="MessageCount" label="总发言数" sortable='custom' width="120px"></el-table-column>
        <el-table-column prop="MessageCount30" label="30日发言数" sortable='custom' width="120px"></el-table-column>
        <el-table-column prop="MemberFatherNickName" label="邀请人"></el-table-column>
        <el-table-column prop="SonsCount" label="邀请入群人数" sortable='custom' width="120px"></el-table-column>
        <el-table-column prop="CreateTime" label="入群时间" width="150px"></el-table-column>
        <el-table-column prop="MemberIdentiyStr" label="身份">
          <template #default="scope">
              <div class="action-wrap" v-if="scope.row.MemberIdentity==0">
                <el-button type="primary" @click="addAction(scope.row)">加入白名单</el-button>
              </div>
              <div v-else>{{scope.row.MemberIdentiyStr}}</div>
            </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template #default="scope">
              <div v-if="scope.row.MemberIdentity==0">
                <el-button type="primary" @click="outGroupAction(scope.row)">踢出群聊</el-button>
              </div>
            </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj" 
        @changePage="changePage" 
        @changeSize="changeSize" />
    </div>
    
  </div>
</template>

<script>

import { reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox,ElMessage } from 'element-plus';
import {GetGroupMemberList,GetAllGroup,GroupMemberSetRoleWhite,RemoveAllGroup} from "@/helper/group";
import Tool from '@/assets/js/tools'

export default {
  name: "MemberList",
  components: {
      TableFoot
    },
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const SID = useRoute().query.SID
    const SerialNo = useRoute().query.SerialNo
    const groups = ref([])

  
    onMounted(()=>{
      GetAllGroup({}).then(res=>{
        groups.value = res.List
        groups.value = [{GroupID:0,GroupName:"全部"}].concat(groups.value)
        groups.value.forEach(item=>{
          if(SID == item.GroupID){
            searchOption.groupObj = item
          }
        })
        searchAction()
      })   
    })

    //处理请求数据
    function dealwithRequestData(){
      return {
          Name:searchOption.key||"",
          PageNum:pageObj.PageIndex,
          PageSize:pageObj.PageSize,
          GroupID:searchOption.groupObj?searchOption.groupObj.GroupID:0,
          OrderBy:searchOption.orderBy||0,
        }
    }

    /**********************点击事件***************************/
    //提出群聊
    function outGroupAction(item) {
      let param={
        RobotSerialNo:SerialNo,
        SetBlackList:0,
        MemberSeiralNo:item.MemberSerialNo
      }
      ElMessageBox.confirm(`确认将用户【${item.MemberNickName}】从所有群中踢出？`, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          RemoveAllGroup(param).then(res=>{
            searchAction();
            ElMessage({
              type: 'success',
              message: '操作成功!',
            });
          })
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
          });

    }


    //导出
    function exportAction(){
      let param = dealwithRequestData()
      param.Output = 1
      GetGroupMemberList(param).then(res=>{
        window.location.href = res.File;
        ElMessage({
            type: 'success',
            message: '导出成功!',
        });
      })
    }


    //加入白名单
    function addAction(item){
        ElMessageBox.confirm(`确认将用户【${item.MemberNickName}】添加到白名单？`, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          GroupMemberSetRoleWhite({MemberSerialNo:item.MemberSerialNo}).then(res=>{
            searchAction();
            ElMessage({
              type: 'success',
              message: '设置成功!',
            });
          }) 
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
          });
    }

    //切换
    function changeSelectAction(){
        pageObj.PageIndex = 1;
        searchAction()
    }
    //转化身份
    function changeMemberStatu(num){
      if(num==0){
        return "普通群成员"
      } else if(num==1){
        return "管理员"
      } else if(num==2){
        return "群主"
      } else if(num==3){
        return "白名单"
      }
    }
    /**********************搜索***************************/
    const groupList = ref([]) 
    const searchOption = reactive({
        groupObj:{},
        key:'',
        orderBy:0
    })

    //执行搜索
    function searchAction(){
        GetGroupMemberList(dealwithRequestData()).then(res=>{
          console.log(res);
          tableData.value = res.List;
          pageObj.TotalRow = res.TotalCount;
          res.List.forEach(item=>{
            item.MemberIdentiyStr=changeMemberStatu(item.MemberIdentity) 
          })
        })
    }

    //重置条件
    function resetAction(){
        searchOption.key = '';
        searchOption.groupObj = {};
        searchOption.orderBy = 0;
        searchAction();
    }

    //排序
    const changeSort=(obj)=>{
      if(obj.order == "descending"&&obj.prop=='MessageCount'){
        searchOption.orderBy = 6;
        pageObj.PageIndex = 1
        searchAction();
      } else if(obj.order == "descending"&&obj.prop=='MessageCount30'){
        searchOption.orderBy = 8;
        pageObj.PageIndex = 1
        searchAction();
      } else if(obj.order == "descending"&&obj.prop=='SonsCount'){
        searchOption.orderBy = 4;
        pageObj.PageIndex = 1
        searchAction();
      } else if(obj.order == "ascending"&&obj.prop=='MessageCount'){
        searchOption.orderBy = 5;
        pageObj.PageIndex = 1
        searchAction();
      } else if(obj.order == "ascending"&&obj.prop=='MessageCount30'){
        searchOption.orderBy = 7;
        pageObj.PageIndex = 1
        searchAction();
      } else if(obj.order == "ascending"&&obj.prop=='SonsCount'){
        searchOption.orderBy = 3;
        pageObj.PageIndex = 1
        searchAction();
      } else {
        searchOption.orderBy = 0;
        pageObj.PageIndex = 1
        searchAction();
      }

      
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
        TotalRow: 0,
        PageIndex: 1,
        PageSize: 10,
    })
    //页码改变
    function changePage(index) {
        pageObj.PageIndex = index;
        searchAction();
    };
    function changeSize(size) {
        pageObj.PageSize = size;
        pageObj.PageIndex = 1;
        searchAction();
    }

    return {
      tableData,
      searchOption,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      exportAction,
      changeSort,
      groupList,
      addAction,
      groups,
      changeSelectAction,
      dealwithRequestData,
      outGroupAction

    };
  },
};
</script>
<style lang="scss" scoped>
.action-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    i{
        margin-left: 2px;
    }

    img{
        width: 40px;
        height: 40px;
    }

}

</style>